import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { IonicModule } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { InterceptorService } from './app/services/_core/interceptor/interceptor.service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { StorageConfigService } from './app/services/_core/storage/storage-config.service';

if (environment.production) {
  enableProdMode();
}

// Determine which storage name to use based on the URL path
function determineStorageName(): string {
  const path = location.pathname;
  return path.includes('response') ? 'response_db' : 'outreach_db';
}

// Determine which store name to use based on the URL path
function determineStoreName(): string {
  const path = location.pathname;
  return path.includes('response')
    ? '_ionicstorage_response'
    : '_ionicstorage_outreach';
}

const storageName = determineStorageName();

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    provideHttpClient(),
    provideIonicAngular(),
    importProvidersFrom(
      IonicStorageModule.forRoot({
        name: storageName,
        storeName: determineStoreName(),
      })
    ),
    importProvidersFrom(IonicModule),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    {
      provide: StorageConfigService,
      useFactory: () => {
        const configService = new StorageConfigService();
        configService.setStorageName(storageName);
        return configService;
      },
    },
  ],
});
