// *** BUMP THIS VERSION ONLY WHEN UPDATING STARTER TEMPLATE ***
// BH Starter Template Version: 8.0.0

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'ECONSULTS',

  // App Description (full name)
  appDescription: 'eConsults',

  // App Version Number
  appVersion: '8.0.0',

  // App URL Scheme
  appUrlScheme: 'econsult:',

  // Web URL
  webUrl: 'https://dev.econsult.apps.baystatehealth.org',

  // App's REST Service API Root URL
  //build: 2cf37441-3c82-4d60-98c1-5272f1d8b8f0
  //dev: fe7f41c1-d4c0-4b88-a73d-9eaa6c038aff
  apiUrl:
    'https://fhir-ehr.sandboxcerner.com/r4/2cf37441-3c82-4d60-98c1-5272f1d8b8f0',

  // AWS Cognito API Root URL
  awsCognitoUrl: 'https://bh-dev-auth.auth.us-east-1.amazoncognito.com',

  // AWS Cognito Client ID for Auth (Needs client ID specific to app)
  awsCognitoClientId: '7d2qpth3chnv9a41bj3a1s2oe9',

  // FHIR Client ID Outreach
  fhirClientIdOutreach: '43be8a41-dcb2-48a6-8248-ae830d110f14',

  // FHIR Client ID Response
  fhirClientIdResponse: '85562822-2297-4fb6-90b5-1eff2c2b438f',

  // FHIR Scope
  fhirScope: [
    'launch',
    'online_access',
    'openid',
    'fhirUser',
    'user/Practitioner.read',
    'user/Patient.read',
    'user/Patient.write',
    'user/DocumentReference.read',
    'user/DocumentReference.write',
    'user/Condition.read',
    'user/Condition.write',
    'user/Procedure.read',
    'user/Procedure.write',
    'user/Observation.read',
    'user/Observation.write',
    'user/Encounter.read',
    'user/Encounter.write',

    'user/Binary.read',
    'user/Communication.read',
    'user/Communication.write',
    'patient/Patient.read',
    'patient/Observation.read',
  ],

  // Using SSO Authentication
  ssoEnabled: true,

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.econsults.apps.baystatehealth.org',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://appstore.baystatehealth.org/econsult/',

  // Reset password link URL
  resetPwdUrl:
    'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl:
    'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set theme preference: user, light, dark
  // 'user' option will allow user to select theme preference (recommended).
  // 'light' option locks app into light mode, and user cannot change it
  // 'dark' option locks app into dark mode, and user cannot change it
  theme: 'user',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,

  // Enable Multi-language support
  // Shows the Select Language feature on login page, user menu, and account page
  // Update translator service with support languages
  enableMultiLanguageSupport: true,

  // FHIR base URL for development environment
  fhirBaseUrl: 'https://fhir-ehr.sandboxcerner.com/r4',
};
