import { ThemeOption } from './../../../models/_core/theme-option';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserState } from './../../../models/_core/user-state';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { AlertController, ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Observable,
  BehaviorSubject,
  firstValueFrom,
  throwError,
  from,
  switchMap,
  map,
  catchError,
} from 'rxjs';
import { NotificationsService } from '../notifications/notifications.service';
import { StorageService } from '../storage/storage.service';
import moment from 'moment';
import { AuthState } from 'src/app/models/_core/auth-state';
import { SSOTokenResponse } from 'src/app/models/_core/sso-token-response';
import { UserDeviceService } from '../user-device/user-device.service';
import { Browser } from '@capacitor/browser';
import { AwsSettings } from 'src/app/models/_core/aws-settings';
import { AnalyticsService } from '../analytics/analytics.service';
import { User } from 'src/app/models/user';

import { oauth2 as FHIR } from 'fhirclient';

/**
 * ID: bh-auth-service
 * Name: BH Auth Service
 * Description: Service used for managing authentication and user state
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Improved open modal + alert handling; improved UX
 * 2022-05-23 - MW - v4: Updated depreciated value/error handling
 * 2022-05-27 - MW - v5: Implemented user state and theme subjects
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  env = environment;
  authUser: BehaviorSubject<User> = new BehaviorSubject(null);
  userState: UserState = {};
  userStateSubject: BehaviorSubject<UserState> = new BehaviorSubject({});
  themeSubject: BehaviorSubject<ThemeOption> = new BehaviorSubject('light');
  menuOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  timeoutWarningMs = 60000;
  timeoutLogoutMs = 120000;
  inactivitySubject = new BehaviorSubject<number>(0);
  inactivityTimer = null;
  targetUrl = '';
  awsCognitoClientId = this.env.awsCognitoClientId;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private storageService: StorageService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private helpers: HelperUtilitiesService,
    private navService: NavigationService,
    private deviceService: UserDeviceService,
    private analytics: AnalyticsService
  ) {
    this.getUserStateFromStorage();
  }

  /**
   * Gets Auth User object
   * Recommend subscribing to authUser directly
   */
  getAuthUser(): User {
    const authUser = this.authUser.getValue();
    return authUser ? authUser : {};
  }

  /***
   * Updates Auth User object with provided object
   * @param authUser User object to replace existing value
   */
  setAuthUser(authUser: User) {
    this.authUser.next(authUser);
  }

  /**
   * Gets User State object
   * Recommend subscribing to userStateSubject directly
   */
  getUserState(): UserState {
    return this.userStateSubject.getValue();
  }

  /***
   * Updates User State subject object
   * @param userState User State to update with
   */
  setUserState(userState: UserState) {
    this.userStateSubject.next(userState);
  }

  /**
   * Toggles the menu open and closed
   */
  toggleMenu() {
    const isMenuOpen = this.menuOpen.getValue();
    this.menuOpen.next(!isMenuOpen);
  }

  /**
   * Gets active theme
   * Recommend subscribing to themeSubject directly
   */
  getTheme(): ThemeOption {
    return this.themeSubject.getValue();
  }

  /***
   * Updates theme subject object
   * @param theme ThemeOption to update with
   */
  setTheme(theme: ThemeOption) {
    this.themeSubject.next(theme);
  }

  /***
   * Gets the user's state from storage
   */
  async getUserStateFromStorage() {
    this.userState = await this.storageService.getData('userState');
  }

  /***
   * Save the user's state to local storage
   */
  async saveUserStateToStorage() {
    if (
      !this.env.storeToken &&
      this.userState.authUser &&
      this.userState.authUser.token
    ) {
      this.userState.authUser.token = null;
    }
    this.userStateSubject.next(this.userState);
    this.storageService.saveData('userState', this.userState);
  }

  /**
   * Starts inactivity timer.
   * Should be called after successfully logging in
   */
  public startInactivityTimer() {
    if (this.env.requireTimeout) {
      this.timeoutLogoutMs = this.env.timeoutThreshold;
      this.timeoutWarningMs = this.timeoutLogoutMs - 30000;
      this.inactivityTimer = setInterval(() => {
        let time = this.inactivitySubject.getValue();
        time += 1000;
        // console.log('Inactivity: ', time)
        this.inactivitySubject.next(time);
        this.checkForTimeout();
      }, 1000);
    }
  }

  /**
   * Check for session timeout, display appropriate alert if timing out.
   */
  public async checkForTimeout() {
    const time = this.inactivitySubject.getValue();
    if (time === this.timeoutWarningMs) {
      const alert = await this.alertCtrl.create({
        header: 'Still there?',
        message: 'You will be signed out soon due to inactivity.',
        cssClass: 'wide-alert warning',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay signed in',
            cssClass: 'primary',
            handler: (val) => {
              this.bumpInactivityTimer();
            },
          },
          {
            text: 'Sign out',
            handler: async (val) => {
              await this.dismissAllModalsAndAlerts();
              this.logout(false, true);
            },
          },
        ],
      });
      await alert.present();
    } else if (time === this.timeoutLogoutMs) {
      await this.dismissAllModalsAndAlerts();
      this.logout(true, true);
    }
  }

  /**
   * Dismisses all open alerts and modals
   */
  async dismissAllModalsAndAlerts(): Promise<boolean> {
    // Dismiss alerts
    for (let i = 0; i < 25; i++) {
      const alert = await this.alertCtrl.getTop();
      if (alert) {
        await alert.dismiss();
      } else {
        break;
      }
    }

    // Dismiss modals
    for (let i = 0; i < 25; i++) {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await modal.dismiss();
      } else {
        break;
      }
    }

    return Promise.resolve(true);
  }

  /**
   * Bumps activity timer, preventing auto-timeout
   */
  public bumpInactivityTimer() {
    this.inactivitySubject.next(0);
  }

  async fhirAuthorize(issuer: string, target = '') {
    const redirectRoot = this.getUriFromLocation();
    let redirectUri = redirectRoot + '/redirect';

    let clientId = this.env.fhirClientIdOutreach; // default to outreach

    if (target === 'response') {
      clientId = this.env.fhirClientIdResponse;
      redirectUri = redirectRoot + '/redirect/response';
    } else if (target === 'outreach') {
      clientId = this.env.fhirClientIdOutreach;
      redirectUri = redirectRoot + '/redirect/outreach';
    }

    FHIR.authorize({
      clientId: clientId,
      scope: this.env.fhirScope.join(' '),
      redirect_uri: redirectUri,
      iss: issuer,
      completeInTarget: true,
    });
  }

  /**
   * Refreshes the access token using a refresh token according to SMART on FHIR standard
   * @param refreshToken The refresh token from a prior authorization response
   * @returns Observable with the token response
   */
  refreshSSOToken(refreshToken: string): Observable<SSOTokenResponse> {
    return from(this.storageService.getData('tokenEndpoint', false)).pipe(
      switchMap((tokenEndpoint) => {
        if (!tokenEndpoint) {
          console.error('Token endpoint not found in storage');
          return throwError(() => new Error('Token endpoint not available'));
        }

        return from(this.storageService.getData('fhirClientId', false)).pipe(
          switchMap((clientId) => {
            if (!clientId) {
              console.error('Client ID not found in storage');
              return throwError(
                () => new Error('Client ID not available for token refresh')
              );
            }

            // console.log('Using stored client ID for refresh:', clientId);
            // console.log('Using token endpoint:', tokenEndpoint);

            const body = {
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              client_id: clientId,
            };

            const encodedBody = this.convertToFormData(body);
            const headers = new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
            });

            // Explicitly set the response type to json and type it as SSOTokenResponse
            return this.http
              .post<SSOTokenResponse>(tokenEndpoint, encodedBody, {
                headers: headers,
                responseType: 'json' as 'json',
              })
              .pipe(
                map((data: SSOTokenResponse) => {
                  // console.log(
                  //   'Token refresh successful, full response:',
                  //   JSON.stringify(data, null, 2)
                  // );

                  // Get current user and preserve all existing properties
                  let authUser = this.getAuthUser();
                  // console.log(
                  //   'Current authUser before update:',
                  //   JSON.stringify(authUser, null, 2)
                  // );

                  // If authUser is empty (which might be the case), create a basic object
                  if (!authUser || Object.keys(authUser).length === 0) {
                    authUser = {
                      token: data.access_token,
                      userName: data.username || '',
                      userId: data.username || '',
                      ssoTokenResponse: data,
                      role: 'USER',
                    };
                  } else {
                    // Update token while preserving all other properties
                    authUser = {
                      ...authUser, // Keep all existing properties
                      token: data.access_token, // Update token
                      ssoTokenResponse: {
                        ...authUser.ssoTokenResponse, // Keep existing SSO data
                        ...data, // Update with new token data
                      },
                    };

                    // Ensure username and userId are preserved
                    if (
                      data.username &&
                      (!authUser.userName || !authUser.userId)
                    ) {
                      authUser.userName = data.username;
                      authUser.userId = data.username;
                    }
                  }

                  // console.log(
                  //   'Updated authUser after refresh:',
                  //   JSON.stringify(authUser, null, 2)
                  // );

                  // Update the BehaviorSubject
                  this.setAuthUser(authUser);

                  // Update userState while preserving other state properties
                  this.userState = {
                    ...this.userState, // Keep existing state
                    authUser: authUser, // Update auth user
                    authState: AuthState.LOGGED_IN,
                    sessionRefreshed: moment().format('M/D/YYYY HH:mm'),
                  };

                  this.setUserState(this.userState);

                  // Save to storage
                  this.saveUserStateToStorage();
                  //console.log('Saved updated user state to storage');

                  // If a new refresh token is returned, store it
                  if (data.refresh_token) {
                    this.storageService.saveData(
                      'refreshToken',
                      data.refresh_token
                    );
                    console.log('Saved new refresh token to storage');
                  }

                  return data;
                }),
                catchError((error) => {
                  console.error('Token refresh failed:', error);
                  // If refresh fails, user needs to re-authenticate
                  if (error.status === 401) {
                    this.logout(true);
                  }
                  return throwError(() => error);
                })
              );
          })
        );
      })
    );
  }

  // Helper method to convert an object to form-urlencoded format
  private convertToFormData(obj: any): string {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join('&');
  }

  /***
   * Logs user into application
   * @param userName User Name
   * @param password  Password
   * @returns User Login Payload
   */

  async initSession(ssoTokenRes: SSOTokenResponse): Promise<void> {
    try {
      let authUser = this.getAuthUser();
      authUser.token = ssoTokenRes.access_token;
      // console.log('initSession: ssoTokenRes: ', ssoTokenRes);
      this.setAuthUser(authUser);
      //const res = await firstValueFrom(this.validateUser());
      if (ssoTokenRes && ssoTokenRes.username) {
        // Update user object
        authUser.userName = ssoTokenRes.username;
        authUser.token = ssoTokenRes.access_token;
        authUser.userId = ssoTokenRes.username;
        authUser.ssoTokenResponse = ssoTokenRes;

        //authUser.firstName = this.helpers.getFirstName(res.fullName);
        authUser.role = 'USER';
        // Update analytics user
        this.analytics.analyticsData.userid = authUser.userName.toLowerCase();
        // Reapply the access token
        // Set user
        this.setAuthUser(authUser);
        // Load navigation
        // this.navService.loadNavigation();
        // Prepare App State
        this.userState.sessionAppVersion =
          this.env.appVersion + '-' + this.env.env;
        this.userState.userId = authUser.userId;
        this.userState.userName = authUser.userName;
        this.userState.environment = this.env;
        this.userState.lastLoggedIn = moment().format('M/D/YYYY HH:mm');
        this.userState.authState = AuthState.LOGGED_IN;
        this.userState.authUser = authUser;
        this.saveUserStateToStorage();
        // Save refresh token
        await this.storageService.saveData(
          'refreshToken',
          ssoTokenRes.refresh_token
        );
        const rtok = this.storageService.getData('refreshToken', false);
        // console.log('initSession: rtok: ', rtok);
      }
      return Promise.resolve();
    } catch (err) {
      throw err;
    }
  }

  setRole(authUser: User): 'USER' | 'ADMIN' | 'SYS_ADMIN' {
    if (authUser.roles) {
      if (authUser.roles.includes('SYS_ADMIN')) {
        return 'SYS_ADMIN';
      } else if (authUser.roles.includes('ADMIN')) {
        return 'ADMIN';
      } else {
        return 'USER';
      }
    } else if (authUser.role) {
      return authUser.role;
    } else {
      return 'USER';
    }
  }

  decodeJwt(token: string): any {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      //console.log('decodeJwt: ', jsonPayload);
      return JSON.parse(jsonPayload);
    } else {
      return null;
    }
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeJwt(token);
    // console.log('authService: isTokenExpired: ', decodedToken);

    if (decodedToken && decodedToken.exp) {
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const currentTime = new Date().getTime();
      // const expireMoment = moment(expirationTime);
      // console.log('authService: expireMoment: ', expireMoment.format('M/D/YYYY h:mm a'));

      return expirationTime <= currentTime;
    }

    // If the 'exp' claim is not present, consider the token as not expired
    return false;
  }

  getUriFromLocation(): string {
    // Get the current location
    const currentLocation = window.location;

    // Extract protocol, domain, and port
    const protocol = currentLocation.protocol;
    const domain = currentLocation.hostname;
    const port = currentLocation.port;
    const portSuffix = port && port !== '80' ? ':' + port : '';

    // console.log('Protocol:', protocol);
    // console.log('Domain:', domain);
    // console.log('Port:', port);

    if (protocol === 'capacitor:' || this.deviceService.isNotBrowser()) {
      // Return web url for universal links
      return this.env.webUrl;
    } else {
      // Return localhost
      return protocol + '//' + domain + portSuffix;
    }

    // return protocol + '//' + domain + portSuffix;
  }

  /***
   * Logs user out
   * @param isExpired Determines if session expired
   * @param redirectToLogin Designates redirection to login page
   */
  logout(isExpired = false, redirectToLogin = true) {
    this.authUser.next(null);
    this.inactivitySubject.next(0);
    clearInterval(this.inactivityTimer);
    this.inactivityTimer = null;
    this.navService.navPages = [];
    this.alertCtrl.getTop().then((alert) => {
      if (alert) {
        alert.dismiss();
      }
    });
    if (isExpired) {
      this.userState.authState = AuthState.EXPIRED;
      this.notifications.showAlert(
        'Session expired',
        'You were signed out due to inactivity.',
        'danger'
      );
    } else {
      this.userState.authState = AuthState.LOGGED_OUT;
    }

    this.storageService.removeData('userState');

    if (redirectToLogin) {
      this.navService.navigateBack('login');
    }
  }

  getTenantId(): string {
    const authUser = this.getAuthUser();
    return authUser?.ssoTokenResponse?.tenant;
  }
}
