import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FhirLoggingService {
  private isFhirRequest(url: string): boolean {
    return url.includes('fhir-ehr.sandboxcerner.com');
  }

  logRequest(req: HttpRequest<any>) {
    if (!this.isFhirRequest(req.url) || environment.production) return;

    const timestamp = new Date().toISOString();
    console.log(`[FHIR Request] ${timestamp} - ${req.method} ${req.url}`);
  }

  logResponse(req: HttpRequest<any>, event: HttpResponse<any>) {
    if (!this.isFhirRequest(req.url) || environment.production) return;

    const xRequestId = event.headers.get('x-request-id');
    const opcRequestId = event.headers.get('opc-request-id');

    console.log('[FHIR Response]', {
      timestamp: new Date().toISOString(),
      url: req.url,
      status: event.status,
      xRequestId,
      opcRequestId,
    });
  }

  logError(req: HttpRequest<any>, error: HttpErrorResponse) {
    if (!this.isFhirRequest(req.url) || environment.production) return;

    const xRequestId = error.headers?.get('x-request-id');
    const opcRequestId = error.headers?.get('opc-request-id');

    console.error('[FHIR Error]', {
      timestamp: new Date().toISOString(),
      url: req.url,
      status: error.status,
      statusText: error.statusText,
      xRequestId,
      opcRequestId,
      error: error.message,
    });
  }
}
