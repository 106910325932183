import { UserState } from './../../../models/_core/user-state';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../storage/storage.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  throwError,
  from,
  firstValueFrom,
  BehaviorSubject,
} from 'rxjs';
import {
  catchError,
  switchMap,
  tap,
  filter,
  take,
  finalize,
} from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { NotificationsService } from '../notifications/notifications.service';
import { FhirLoggingService } from '../../../services/fhir/fhir-logging/fhir-logging.service';

/**
 * ID: bh-interceptor-service
 * Name: BH Interceptor Service
 * Description: Service used to manage http requests and auth tokens
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Added BH-DONT-BUMP-INACTIVITY-TIMER header handling; will ignore timer bump
 * 2021-10-11 - MW - v4: Fixed issue with stored token session management
 * 2022-05-19 - MW - v5: Fixed persisten token issue
 */
@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  env = environment;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private notifications: NotificationsService,
    private fhirLogger: FhirLoggingService
  ) {}

  // Intercepts all HTTP requests!
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if this is a token refresh request by URL pattern
    const isTokenEndpoint = this.isTokenRefreshRequest(request);

    // Skip token for certain URLs or if it's a token endpoint
    if (this.shouldSkipToken(request, isTokenEndpoint)) {
      return this.handleRequestWithoutToken(request, next);
    }

    // Handle authenticated request
    return this.handleAuthenticatedRequest(request, next, isTokenEndpoint);
  }

  private isTokenRefreshRequest(request: HttpRequest<any>): boolean {
    return (
      request.url.includes('/protocols/oauth2/profiles/smart-v1/token') &&
      request.body &&
      request.body.toString().includes('grant_type=refresh_token')
    );
  }

  private shouldSkipToken(
    request: HttpRequest<any>,
    isTokenEndpoint: boolean
  ): boolean {
    // Skip token for certain URLs
    const skipUrls = [
      'login',
      'token',
      'auth',
      // Add other URLs that don't need authentication
    ];

    return skipUrls.some((url) => request.url.includes(url)) || isTokenEndpoint;
  }

  private handleRequestWithoutToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Log FHIR request if not in production
    if (!environment.production) {
      this.fhirLogger.logRequest(request);
    }

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse && !environment.production) {
            // Log FHIR response if not in production
            this.fhirLogger.logResponse(request, event);
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse && !environment.production) {
            // Log FHIR error if not in production
            this.fhirLogger.logError(request, error);
          }
        }
      )
    );
  }

  private handleAuthenticatedRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    isTokenEndpoint: boolean
  ): Observable<HttpEvent<any>> {
    // Get the current auth user
    const authUser = this.authService.getAuthUser();

    // Create an observable that will get the token and make the request
    return from(this.getToken(authUser)).pipe(
      switchMap((token) => {
        // Clone the request with the token
        const authReq = this.addToken(request, token);

        // Log FHIR request if not in production
        if (!environment.production) {
          this.fhirLogger.logRequest(authReq);
        }

        // Handle the request and catch any errors
        return next.handle(authReq).pipe(
          tap((event) => {
            if (event instanceof HttpResponse && !environment.production) {
              // Log FHIR response if not in production
              this.fhirLogger.logResponse(request, event);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            // Log FHIR error if not in production
            if (!environment.production) {
              this.fhirLogger.logError(request, error);
            }

            // Handle 401 errors with token refresh
            if (error.status === 401) {
              return this.handle401Error(request, next);
            }

            // For other errors, just pass them through
            return throwError(() => error);
          })
        );
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      if (!environment.production) {
        console.log('Got 401, attempting to refresh token');
      }

      return from(this.refreshToken()).pipe(
        switchMap((newToken) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken);

          if (newToken) {
            // We got a new token, retry the request
            const newAuthReq = this.addToken(request, newToken);

            // Log FHIR request for the retry if not in production
            if (!environment.production) {
              this.fhirLogger.logRequest(newAuthReq);
            }

            return next.handle(newAuthReq).pipe(
              tap((event) => {
                if (event instanceof HttpResponse && !environment.production) {
                  // Log FHIR response for the retry if not in production
                  this.fhirLogger.logResponse(newAuthReq, event);
                }
              }),
              catchError((retryError) => {
                // Log FHIR error for the retry if not in production
                if (!environment.production) {
                  this.fhirLogger.logError(newAuthReq, retryError);
                }
                return throwError(() => retryError);
              })
            );
          } else {
            // Token refresh failed, redirect to login
            this.authService.logout(true, true);
            return throwError(() => new Error('Token refresh failed'));
          }
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      // If refresh is in progress, wait until new token is retrieved
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          const newAuthReq = this.addToken(request, token);
          return next.handle(newAuthReq);
        })
      );
    }
  }

  private async refreshToken(): Promise<string> {
    try {
      // Check if we have a refresh token
      const refreshToken = await this.storageService.getData(
        'refreshToken',
        false
      );

      if (refreshToken) {
        if (!environment.production) {
          console.log('Refreshing access token with refresh token');
        }

        const res = await firstValueFrom(
          this.authService.refreshSSOToken(refreshToken)
        );

        if (res && res.access_token) {
          // Update the user session with the new token
          await this.authService.initSession(res);

          if (!environment.production) {
            console.log('User session updated with new token');
          }

          return res.access_token;
        }
      }

      return null;
    } catch (err) {
      if (!environment.production) {
        console.error('Token refresh error:', err);
      }

      // If refresh fails, clear the token and return null
      await this.storageService.removeData('refreshToken');
      return null;
    }
  }

  private async getToken(authUser: any = null): Promise<string> {
    let user = authUser;
    this.notifications.suppressErrors = true;

    if (!authUser && this.env.storeToken) {
      const userState = (await this.storageService.getData(
        'userState',
        false
      )) as UserState;
      if (userState) {
        user = userState.authUser;
      }
    }

    // Get token from auth user or from storage
    if (user && user.token) {
      return user.token;
    }

    return null;
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return request;
  }
}
