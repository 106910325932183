import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiError } from 'src/app/models/_core/api-error';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { IonContent, IonButton, IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.page.html',
  styleUrls: ['./error-modal.page.scss'],
  standalone: true,
  imports: [
    IonContent,
    CommonModule,
    FormsModule,
    PipesModule,
    IonButton,
    IonIcon,
  ],
})
export class ErrorModalPage implements OnInit {
  @Input() header = 'Something went wrong';
  @Input() message =
    "Because of a technical issue on our end, we couldn't complete the desired action. Please try again.";
  @Input() apiError: ApiError | HttpErrorResponse | Error | any;
  @Input() action: string;

  errorString: string;
  showDetails = false;
  errorDetails: any = {};

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    console.log(
      'error-modal.ngOnInit: apiError keys',
      this.apiError ? Object.keys(this.apiError) : 'null'
    );

    // Extract and format error details
    this.extractErrorDetails();

    // Format error string for display
    if (this.errorString) {
      this.errorString = JSON.stringify(this.apiError);
      this.errorString = this.errorString.replace(/',/g, "',<br>");
      this.errorString = this.errorString.replace(/{/g, '{<br>');
      this.errorString = this.errorString.replace(/}/g, '}<br>');
    }
    console.log('error-modal.ngOnChanges: errorString', this.errorString);
  }

  extractErrorDetails() {
    this.errorDetails = {
      message: 'Unknown error',
      action: this.action || 'Unknown action',
      errorName: this.apiError?.name || 'Unknown',
      path: '',
      requestId: '',
      statusCode: -1,
      timestamp: new Date().toISOString(),
    };

    // Handle HttpErrorResponse
    if (this.apiError instanceof HttpErrorResponse) {
      this.errorDetails.message =
        this.apiError.message || this.apiError.statusText;
      this.errorDetails.path = this.apiError.url;
      this.errorDetails.statusCode = this.apiError.status;

      // Extract request ID from headers if available
      const requestId =
        this.apiError.headers?.get('x-request-id') ||
        this.apiError.headers?.get('x-correlation-id');
      if (requestId) {
        this.errorDetails.requestId = requestId;
      }

      // Try to extract more details from error response
      if (this.apiError.error) {
        if (typeof this.apiError.error === 'string') {
          try {
            const parsedError = JSON.parse(this.apiError.error);
            if (parsedError.message)
              this.errorDetails.message = parsedError.message;
          } catch (e) {
            // If parsing fails, use the error string directly
            this.errorDetails.message = this.apiError.error;
          }
        } else if (typeof this.apiError.error === 'object') {
          // Extract useful fields from error object
          if (this.apiError.error.message)
            this.errorDetails.message = this.apiError.error.message;
          if (
            this.apiError.error.issue &&
            Array.isArray(this.apiError.error.issue)
          ) {
            // Handle FHIR OperationOutcome format
            const issues = this.apiError.error.issue
              .map(
                (issue) =>
                  `${issue.severity}: ${
                    issue.diagnostics || issue.details?.text || ''
                  }`
              )
              .join('; ');
            if (issues) this.errorDetails.message = issues;
          }
        }
      }
    }
    // Handle ApiError
    else if (this.apiError && this.apiError.message) {
      this.errorDetails.message = this.apiError.message;
      this.errorDetails.path = this.apiError.path || '';
      this.errorDetails.requestId = this.apiError.requestId || '';
      this.errorDetails.statusCode = this.apiError.statusCode || -1;
      this.errorDetails.timestamp =
        this.apiError.timestamp || new Date().toISOString();
    }
    // Handle standard Error
    else if (this.apiError instanceof Error) {
      this.errorDetails.message = this.apiError.message;
      this.errorDetails.errorName = this.apiError.name;
      this.errorDetails.stack = this.apiError.stack;
    }
  }

  presentDetail() {
    this.showDetails = true;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
