import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageConfigService {
  private storageName: string;

  setStorageName(name: string) {
    this.storageName = name;
  }

  getStorageName(): string {
    return this.storageName;
  }
}
