<ion-content class="ion-padding">
  <div class="icon-container">
    <ion-icon name="alert-circle-outline" color="danger"></ion-icon>
  </div>
  <h2 class="text-align-center">{{ header | translate }}</h2>
  <p class="text-align-center">{{ message | translate }}</p>
  <div class="button-wrapper">
    <ion-button (click)="dismiss()">{{ 'Dismiss' | translate }}</ion-button>
    <a
      href="javascript:void(0)"
      (click)="presentDetail()"
      *ngIf="apiError && !showDetails"
      >{{ 'See error details' | translate }}</a
    >
  </div>
  <div class="form-group-box" *ngIf="showDetails">
    <h3>{{ 'Error Detail' | translate }}</h3>
    <p>
      {{ 'Please take a screenshot of this Error Detail and attach to an email
      to ' | translate }}
      <a href="mailto:appliedtech@baystatehealth.org"
        >appliedtech&#64;baystatehealth.org</a
      >
      {{ ' or with submission of an I&T help ticket ' | translate }}
      <a href="https://baystatehealth.service-now.com/sp" target="_blank"
        >{{ 'here' | translate }}</a
      >.
    </p>

    <div class="record-row">
      <div class="record-label">{{ 'Message' | translate }}</div>
      <div class="record-value">{{ errorDetails.message }}</div>
    </div>
    <div class="record-row">
      <div class="record-label">{{ 'Action' | translate }}</div>
      <div class="record-value">{{ errorDetails.action }}</div>
    </div>
    <div class="record-row">
      <div class="record-label">{{ 'Error Name' | translate }}</div>
      <div class="record-value">{{ errorDetails.errorName }}</div>
    </div>
    <div class="record-row">
      <div class="record-label">{{ 'Path' | translate }}</div>
      <div class="record-value">{{ errorDetails.path }}</div>
    </div>
    <div class="record-row">
      <div class="record-label">{{ 'Request ID' | translate }}</div>
      <div class="record-value">{{ errorDetails.requestId }}</div>
    </div>
    <div class="record-row">
      <div class="record-label">{{ 'Status Code' | translate }}</div>
      <div class="record-value">{{ errorDetails.statusCode }}</div>
    </div>
    <div class="record-row">
      <div class="record-label">{{ 'Timestamp' | translate }}</div>
      <div class="record-value">
        {{ errorDetails.timestamp | formatTimestamp }}
      </div>
    </div>

    <!-- Add stack trace if available -->
    <div class="record-row" *ngIf="errorDetails.stack">
      <div class="record-label">{{ 'Stack Trace' | translate }}</div>
      <div class="record-value pre-formatted">{{ errorDetails.stack }}</div>
    </div>
  </div>
</ion-content>
